import type { SellBuyBody, SellBuyPage } from '~/types/cms/sellBuy';
import type {
  SellBuyDocument,
  SellBuyDocumentDataBodyParagraphSlice,
} from '~/types/prismic/types.generated';

import {
  asHTMLField,
  asTabText,
  getAllLanguages,
  getLinkUrl,
  transformImage,
} from './transformCommon';

/**
 * Transforms a Prismic SellBuy document into a SpecialtyContent object.
 * @param locale - The current locale.
 * @param locales - The available locales.
 * @param uid - The uid of the document.
 * @param prismicSellBuy - The Prismic Specialty document to transform.
 * @returns - The transformed SpecialtyContent object.
 */
export function transformSellBuy(
  locale: string,
  locales: string[],
  uid: string,
  prismicSellBuy: SellBuyDocument
): SellBuyPage {
  const body = transformBody(prismicSellBuy.data.body);
  const seoDescription = extractTextFromHTML(body[0]?.text ?? '');

  return {
    body: body,
    image: transformImage(prismicSellBuy.data.main_image),
    possibleLanguages: getAllLanguages({
      currentLocale: locale,
      currentUid: uid,
      locales: locales,
      prismicAlternateLanguages: prismicSellBuy.alternate_languages,
      prismicDefaultLang: prismicSellBuy.lang,
    }),
    seoMeta: {
      description: seoDescription,
      ogDescription: seoDescription,
      ogImage: prismicSellBuy.data.main_image.url ?? undefined,
      ogTitle: asTabText(prismicSellBuy.data.tab_title),
    },
    tabTitle: asTabText(prismicSellBuy.data.tab_title),
    title: asHTMLField(prismicSellBuy.data.main_title),
  };
}

/**
 * Transforms the body of a Prismic SellBuy document.
 * @param prismicSellBuy - The Prismic SellBuy document to transform.
 * @returns - The transformed SellBuyBody object.
 */
function transformBody(
  prismicSellBuy: SellBuyDocumentDataBodyParagraphSlice[]
): SellBuyBody[] {
  return prismicSellBuy.map((slice) => ({
    links: slice.items.flatMap((item) => {
      const linkName = asHTMLField(item.link_name);
      return linkName
        ? { label: linkName, url: getLinkUrl(item.link_page) }
        : [];
    }),
    text: asHTMLField(slice.primary.paragraph_text),
    title: asHTMLField(slice.primary.paragraph_title),
  }));
}
