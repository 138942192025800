// 3rd's
import type { $Fetch, FetchOptions, FetchResponse } from 'ofetch';

import type { PageRequest } from './requests';

export type IRequestOptions = {
  mock?: boolean;
};

type MockingOptions = {
  enabled?: boolean;
  global?: boolean;
};

/*
 The FetchFactory acts as a wrapper around an HTTP client.
 It encapsulates the functionality for making API requests asynchronously
 through the call function, utilizing the provided HTTP client.
*/
class FetchFactory {
  private $fetch: $Fetch;
  private mockingOptions: MockingOptions | undefined;

  constructor(fetcher: $Fetch, mockingOptions?: MockingOptions) {
    this.$fetch = fetcher;
    this.mockingOptions = mockingOptions;
  }

  protected buildPageFilters(
    pageRequest?: PageRequest,
    identifier: string = 'fts'
  ) {
    const filters: string[] = [...(pageRequest?.filters || [])];
    if (pageRequest?.search)
      filters.push(`${identifier},fts,${pageRequest.search}`);
    if (filters.length < 1) return '';
    return '?filter=' + filters.join('&filter=') || undefined;
  }

  protected buildPageFiltersSale(
    pageRequest?: PageRequest,
    identifier: string = 'fts'
  ): string {
    const filters: string[] = [...(pageRequest?.filters || [])];
    const filterParams: string[] = [];

    if (pageRequest?.search) {
      filterParams.push(`filter=${identifier},fts,${pageRequest.search}`);
    }

    if (filters.length > 0) {
      const combinedFilters = `filter=channel,in,${filters.join(',')}`;
      filterParams.push(combinedFilters);
    }

    return filterParams.length > 0 ? `?${filterParams.join('&')}` : '';
  }

  /**
   * Function to build query object for the page request
   * @param pageRequest the Page request
   * @returns The query object
   */
  protected buildPageRequest(pageRequest?: PageRequest): FetchOptions['query'] {
    return {
      page: pageRequest?.page || '0',
      size: pageRequest?.size || '5',
      sort: pageRequest?.sort,
    };
  }

  /**
   * The HTTP client is utilized to control the process of making API requests.
   * @param method the HTTP method (GET, POST, ...)
   * @param url the endpoint url
   * @param data the body data
   * @param fetchOptions fetch options
   * @param requestOpts request options, just mocking for now
   * @returns The casted fetched object
   */
  protected async call<T>(
    method: string,
    url: string,
    data?: object,
    fetchOptions?: FetchOptions<'json'>,
    requestOpts?: IRequestOptions
  ): Promise<FetchResponse<T>> {
    if (
      // If the mocking flag or the global mocking flag is enabled AND if mocking is enabled on the app
      (requestOpts?.mock || this.mockingOptions?.global) &&
      this.mockingOptions?.enabled
    ) {
      if (!fetchOptions) fetchOptions = {};
      fetchOptions.baseURL = '';
      url = '/api' + url;
    }

    return this.$fetch.raw<T>(url, {
      body: data,
      method,
      ...fetchOptions,
    });
  }

  protected async delete(
    url: string,
    fetchOptions?: FetchOptions<'json'>,
    requestOpts?: IRequestOptions
  ) {
    return this.call('DELETE', url, undefined, fetchOptions, requestOpts);
  }

  /**
   * Syntactic sugar to make GET requests using the call method
   * @param url the endpoint url
   * @param fetchOptions fetch options
   * @param requestOpts request options, just mocking for now
   * @returns The casted fetched object
   */
  protected async get<T>(
    url: string,
    fetchOptions?: FetchOptions<'json'>,
    requestOpts?: IRequestOptions
  ) {
    return this.call<T>('GET', url, undefined, fetchOptions, requestOpts);
  }

  protected async patch<T>(
    url: string,
    data?: object,
    fetchOptions?: FetchOptions<'json'>,
    requestOpts?: IRequestOptions
  ) {
    return this.call<T>(
      'PATCH',
      url,
      data,
      { headers: { 'Content-Type': 'application/json' }, ...fetchOptions },
      requestOpts
    );
  }

  /**
   * Syntactic sugar to make POST requests using the call method
   * @param url the endpoint url
   * @param data the body data
   * @param fetchOptions fetch options
   * @param requestOpts request options, just mocking for now
   * @returns The casted fetched object
   */
  protected async post<T>(
    url: string,
    data?: object,
    fetchOptions?: FetchOptions<'json'>,
    requestOpts?: IRequestOptions
  ) {
    return this.call<T>(
      'POST',
      url,
      data,
      { headers: { 'Content-Type': 'application/json' }, ...fetchOptions },
      requestOpts
    );
  }

  protected async put<T>(
    url: string,
    data?: object,
    fetchOptions?: FetchOptions<'json'>,
    requestOpts?: IRequestOptions
  ) {
    return this.call<T>(
      'PUT',
      url,
      data,
      { headers: { 'Content-Type': 'application/json' }, ...fetchOptions },
      requestOpts
    );
  }
}

export default FetchFactory;
