export default {
  navContainer: ({ props }) => ({
    class: [
      // Position
      'relative',
      'h-full',

      // Misc
      { 'overflow-hidden': props.scrollable },
    ],
  }),
  navContent: ({ instance }) => ({
    class: [
      'h-full',
      // Overflow and Scrolling
      'overflow-y-hidden overscroll-contain',
      'overscroll-auto',
      'scroll-smooth',
      '[&::-webkit-scrollbar]:hidden',
    ],
  }),
  previousButton: {
    class: [
      // Flexbox and Alignment
      'flex items-center justify-center',

      // Position
      '!absolute',
      'top-0 left-0',
      'z-20',

      // Size and Shape
      'h-full w-10',
      'rounded-none',

      // Colors
      'bg-surface-0 dark:bg-surface-900',
      'text-surface-700 dark:text-surface-0/80',
      'shadow-sm',
    ],
  },
  nextButton: {
    class: [
      // Flexbox and Alignment
      'flex items-center justify-center',

      // Position
      '!absolute',
      'top-0 right-0',
      'z-20',

      // Size and Shape
      'h-full w-10',
      'rounded-none',

      // Colors
      'text-surface-700 dark:text-surface-0/80',
      'bg-surface-0 dark:bg-surface-900',
      'shadow-sm',
    ],
  },
  nav: {
    class: [
      // Flexbox
      'flex flex-1',
      'h-full',

      // Spacing
      'list-none',
      'p-0 m-0',

      // Colors
      'bg-surface-0 dark:bg-surface-900',
      'text-surface-900 dark:text-surface-0/80',
    ],
  },
  tabpanel: {
    header: ({ context, props }) => ({
      class: [
        // Spacing
        'mr-0',
        'flex-1',

        // Misc
        'outline-none',
        props.disabled,
        {
          'opacity-60 cursor-default user-select-none select-none': props.disabled,
        },
      ],
    }),
    headerAction: ({ parent, context, props }) => ({
      class: [
        'relative',
        'h-full',

        // Font
        'font-din-pro',
        
        {
          "font-medium": parent.state.d_activeIndex === !context.index,
        },

        // Flexbox and Alignment
        'flex items-center justify-center',

        // Spacing
        'py-4 px-[1.125rem]',

        // Shape
        {
          'border-b-2': parent.state.d_activeIndex === context.index,
        },

        // Colors and Conditions
        'text-primary',
        {
          'font-bold': parent.state.d_activeIndex === context.index,
          'border-primary': parent.state.d_activeIndex === context.index,
        },

        // States
        //'focus:outline-none focus:outline-offset-0 focus-visible:ring-1 ring-inset focus-visible:ring-primary-400 dark:focus-visible:ring-primary-300',
        {
          'hover:bg-surface-0 dark:hover:bg-surface-800/80':
            parent.state.d_activeIndex !== context.index,
          'hover:text-surface-900 dark:hover:text-surface-0':
            parent.state.d_activeIndex !== context.index,
        },

        // Transitions

        // Misc
        'cursor-pointer select-none text-decoration-none',
        'overflow-hidden',
        'user-select-none',
        'uppercase'
      ],
    }),
    headerTitle: {
      class: [
        // Text
        'leading-none',
        'whitespace-nowrap',
      ],
    },
  },
  panelcontainer: {
    class: [
      // Spacing
      'pb-[1.125rem] pt-[0.875rem]',

      // Shape
      'border-0 rounded-none',
      'border-br-md border-bl-md',

      // Colors
      'bg-surface-0 dark:bg-surface-900',
    ],
  },
  inkbar: {
    class: [
      'hidden'
    ]
  }
};
