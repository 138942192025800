import type { SliceZone } from '@prismicio/client';

import type { LinkItem } from '~/types/cms/common';
import type { HeaderItem, SectionItem } from '~/types/cms/layout';
import type {
  FooterMainDocumentDataLegalTabSlice,
  FooterMainDocumentDataLinksTabSlice,
  FooterMainDocumentDataSocialLinksItem,
  HeaderMainDocumentDataBodyNavBlockSlice,
  HeaderMainDocumentDataBodyNavBlockSliceItem,
} from '~/types/prismic/types.generated.js';

import { getLinkUrl } from './transformCommon';

/**
 * Transform complex prismic types to simpler types.
 *
 * Social links have only a url as an important information.
 * @param prismicLinks The prismic social link object
 * @returns A list of link
 */
export function transformSocialLinks(
  prismicLinks: FooterMainDocumentDataSocialLinksItem[]
): LinkItem[] {
  const links: LinkItem[] = [];

  for (const prismicLink of prismicLinks) {
    const url = getLinkUrl(prismicLink.social_link);
    links.push({ label: url, url: url });
  }
  return links;
}

/**
 * Transform complex prismic types to simpler types.
 *
 * Legal links have a label and a url.
 * @param prismicLegal The prismic legal link object
 * @returns A list of link
 */
export function transformLegalData(
  prismicLegal: SliceZone<FooterMainDocumentDataLegalTabSlice>
) {
  const legalData: LinkItem[] = [];
  for (const prismicLink of prismicLegal) {
    const title = prismicLink.primary.tab_title[0]?.text || '';
    const url = getLinkUrl(prismicLink.primary.tab_link);

    legalData.push({ label: title, url: url });
  }
  return legalData;
}

/**
 * Transform complex prismic types to simpler types.
 *
 * This links are a list of grouped links organized by section.
 * @param prismicLinks The prismic social link object
 * @returns List of links grouped by section
 */
export function transformLinks(
  prismicLinks: SliceZone<FooterMainDocumentDataLinksTabSlice>
): SectionItem[] {
  const sections: SectionItem[] = [];
  for (const prismicLink of prismicLinks) {
    const title = prismicLink.primary.tab_title[0]?.text || '';
    const links: LinkItem[] = [];

    for (const link of prismicLink.items) {
      links.push({
        label: link.tab_item_label[0]?.text || '',
        url: getLinkUrl(link.tab_item_link),
      });
    }

    sections.push({ links: links, title: title });
  }
  return sections;
}

/**
 * Transforms an array of HeaderMainDocumentDataBodyNavBlockSliceItem objects into an array of HeaderItem objects.
 * @param items - The array of items to transform.
 * @param [thirdLevel] - Whether or not to include items with a third level nav item.
 * @returns - The transformed array of HeaderItem objects.
 */
export function transformHeaderItems(
  items: HeaderMainDocumentDataBodyNavBlockSliceItem[],
  thirdLevel = false
): HeaderItem[] {
  const headerItems: HeaderItem[] = [];
  for (const item of items) {
    let children: HeaderItem[] = [];
    if (item.item_id && items.length) {
      const prismicChildren = items.filter(
        (child) => child.parent_item_id === item.item_id
      );
      children = transformHeaderItems(prismicChildren, true);
    }
    const headerItem: HeaderItem = {
      // Search for children only if current item item_id is present and there are more than 1 item.
      children,
      label: item.item_label || '',
      link: getLinkUrl(item.item_link),
    };

    if (thirdLevel || item.item_level === '2nd level nav item') {
      headerItems.push(headerItem);
    }
  }

  return headerItems;
}

/**
 * Transforms an iterable of HeaderMainDocumentDataBodyNavBlockSlice items into an array of HeaderItem objects.
 * @param items - The iterable of HeaderMainDocumentDataBodyNavBlockSlice items to transform.
 * @returns An array of transformed HeaderItem objects.
 */
export function transformHeaderTopLevel(
  items: Iterable<HeaderMainDocumentDataBodyNavBlockSlice>
): HeaderItem[] {
  const headerItems: HeaderItem[] = [];
  for (const item of items) {
    const headerItem = {
      children: transformHeaderItems(item.items),
      label: item.primary.first_level_item_label || '',
      link: getLinkUrl(item.primary.first_level_item_link),
    };
    headerItems.push(headerItem);
  }
  return headerItems;
}
