import type { LegalNoticePage } from '~/types/cms/legalNotice';
import type { LegalNoticeDocument } from '~/types/prismic/types.generated';

import { asHTMLField, asTabText, getAllLanguages } from './transformCommon';

/**
 * Transforms a Prismic LegalNotice document into a LegalNotice object.
 * @param locale - The current locale.
 * @param locales - The available locales.
 * @param uid - The uid of the document.
 * @param prismicSpecialties - The Prismic LegalNotice document to transform.
 * @returns - The transformed LegalNotice object.
 */
export function transformLegalNotice(
  locale: string,
  locales: string[],
  uid: string,
  prismicSpecialties: LegalNoticeDocument
): LegalNoticePage {
  const content = asHTMLField(prismicSpecialties.data.page_content);
  const seoDescription = extractTextFromHTML(content);

  return {
    content: content,
    possibleLanguages: getAllLanguages({
      currentLocale: locale,
      currentUid: uid,
      locales: locales,
      prismicAlternateLanguages: prismicSpecialties.alternate_languages,
      prismicDefaultLang: prismicSpecialties.lang,
    }),
    seoMeta: {
      description: seoDescription,
      ogDescription: seoDescription,
      ogImage: undefined,
      ogTitle: asTabText(prismicSpecialties.data.page_title),
    },
    title: asHTMLField(prismicSpecialties.data.page_title),
  };
}
