import type { Sale } from '~/types/artcurial/artcurial-domain';

import FetchFactory from './factory';

type IRequestOptions = {
  mock?: boolean;
};

export default class AccountModule extends FetchFactory {
  private RESOURCE = '/account';

  async getFavorites(reqOpts?: IRequestOptions) {
    const uri = `${this.RESOURCE}/favorites`;

    const sale = await this.get<Sale[]>(uri, undefined, reqOpts);

    if (!sale._data) return [] as Sale[];
    return sale._data;
  }
}
