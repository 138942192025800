import type { Item, PageWithFilter } from '~/types/artcurial/artcurial-domain';

import type { PageRequest } from './requests';

import FetchFactory, { type IRequestOptions } from './factory';

export default class LotsModule extends FetchFactory {
  private RESOURCE = '/items';
  async getLot(
    instance: string,
    saleRef: string,
    lotId: string,
    reqOpts?: IRequestOptions
  ) {
    const uri = `${this.RESOURCE}/${instance}-${saleRef}-${lotId}`;
    const response = await this.get<Item>(uri, undefined, reqOpts);

    if (!response._data) throw new Error();

    const matchCountryAndSaleRef = /.[^-]*-.[^-]*-/;

    return {
      lot: response._data,
      next: response.headers
        .get('X-Art-Next')
        ?.replace(matchCountryAndSaleRef, ''),
      prev: response.headers
        .get('X-Art-Prev')
        ?.replace(matchCountryAndSaleRef, ''),
    };
  }

  async getLots(
    saleRef: string,
    pageOptions?: { vacationRef?: string } & Omit<PageRequest, 'filters'>,
    reqOpts?: IRequestOptions
  ): Promise<PageWithFilter<Item>> {
    const uri = `/sales/${saleRef}${this.RESOURCE}`;

    const vacationRef = pageOptions?.vacationRef;

    const filters: string[] = [];
    if (vacationRef) {
      filters.push(`vacationRef,eq,${vacationRef}`);
    }

    const filterString = this.buildPageFilters(
      { ...pageOptions, filters },
      'descriptions.fts'
    );

    try {
      const lots = await this.get<PageWithFilter<Item>>(uri + filterString, {
        query: this.buildPageRequest({ ...pageOptions }),
        ...reqOpts,
      });
      if (!lots._data) throw new Error();
      return lots._data;
    } catch {
      return getEmptyPageWithFilter<Item>();
    }
  }
}
