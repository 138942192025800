export default defineNuxtRouteMiddleware((to) => {
  const { data, status } = useAuth();
  const localePath = useLocalePath();

  const registerUrl = localePath('/en/register');
  if (
    status.value === 'authenticated' &&
    !data.value?.user.auth0.claims &&
    to.path !== registerUrl
  ) {
    let redirectUrl: string | undefined = to.path;
    if (to.path === localePath('/en/my-account/profile')) {
      redirectUrl = undefined;
    }
    return navigateTo({
      path: registerUrl,
      query: { callbackUrl: redirectUrl },
    });
  }
});
