import type { ExpertiseDayPage } from '~/types/cms/expertiseDay';
import type { ExpertiseDayDocument } from '~/types/prismic/types.generated';

import {
  asHTMLField,
  asTabText,
  getLinkUrl,
  transformImage,
} from './transformCommon';

/**
 * Transforms a Prismic ExpertiseDay document into a SpecialtyContent object.
 * @param prismicExpertiseDay - The Prismic ExpertiseDay document to transform.
 * @returns - The transformed ExpertiseDay object.
 */
export function transformExpertiseDay(
  prismicExpertiseDay: ExpertiseDayDocument
): ExpertiseDayPage {
  const seoDescription = prismicExpertiseDay.data.cities
    .map((city) => asTabText(city.primary.contact_location))
    .join(', ');

  return {
    cities: prismicExpertiseDay.data.cities.map((city) => ({
      email: getLinkUrl(city.primary.contact_email),
      image: transformImage(city.primary.image),
      location: asHTMLField(city.primary.contact_location),
      name: city.primary.contact_name || undefined,
      observation: city.primary.contact_observation || undefined,
      phone: city.primary.contact_phone_number || undefined,
      title: asHTMLField(city.primary.contact_title),
      url: getLinkUrl(city.primary.contact_url),
    })),
    seoMeta: {
      description: seoDescription,
      ogDescription: seoDescription,
      ogImage:
        prismicExpertiseDay.data.cities[0]?.primary.image?.url || undefined,
      ogTitle: asTabText(prismicExpertiseDay.data.tab_title),
    },
    tabTitle: asTabText(prismicExpertiseDay.data.tab_title),
    title: asHTMLField(prismicExpertiseDay.data.main_title),
  };
}
