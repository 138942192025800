import { asDate } from '@prismicio/client';

import type { NewsPage } from '~/types/cms/news';
import type { NewsAreaDocument } from '~/types/prismic/types.generated';

import {
  asHTMLField,
  asTabText,
  getLinkUrl,
  transformImage,
} from './transformCommon';

/**
 * Transforms a Prismic News Area document into a News object.
 * @param prismicNews - The Prismic News Area document to transform.
 * @returns - The transformed News object.
 */
export function transformNews(prismicNews: NewsAreaDocument): NewsPage {
  const seoDescription = extractTextFromHTML(
    asHTMLField(prismicNews.data.news[0]?.news_extract)
  );

  return {
    image: transformImage(prismicNews.data.image),
    items: prismicNews.data.news.map((item) => {
      return {
        description: asHTMLField(item.news_extract),
        image: transformImage(item.news_image),
        link: {
          label: getLinkUrl(item.news_link),
          url: getLinkUrl(item.news_link),
        },
        publicationDate: asDate(item.news_publishing_date) || undefined,
        title: asHTMLField(item.news_title),
      };
    }),
    seoMeta: {
      description: seoDescription,
      ogDescription: seoDescription,
      ogImage: prismicNews.data.image?.url ?? undefined,
      ogTitle: asTabText(prismicNews.data.title),
    },
    title: asHTMLField(prismicNews.data.title),
  };
}
