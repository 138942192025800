import type {
  Bid,
  Bidder,
  BillingAccount,
  ItemCurrency,
  Page,
  Phone,
  Sale,
  SaleChannel,
} from '~/types/artcurial/artcurial-domain';

import FetchFactory, { type IRequestOptions } from './factory';

interface RegisterSaleOptions {
  billingAccountUuid: BillingAccount['uuid'];
  phone?: Partial<Phone>;
  saleRef: string;
  tenant: string;
  type: Bidder['type'];
}

interface BidData {
  bidCurrency: ItemCurrency;
  bidPrice?: number;
  item: {
    uuid: string;
  };
  saleChannel: SaleChannel;
  saleRef: string;
  tenantId: string;
}
export default class BidderModule extends FetchFactory {
  private RESOURCE = '/bidder';

  async bid(data: BidData) {
    const bid = await this.post<Bid>(this.RESOURCE + '/bid', data);
    return bid._data;
  }

  async changeCeiling(
    saleRef: string,
    ceiling: number,
    reqOpts?: IRequestOptions
  ) {
    const uri = `/set-bid-limit`;

    const lot = await this.patch<Bidder>(
      this.RESOURCE + uri,
      {
        bidLimitRequest: ceiling,
      },
      {
        query: { saleRef },
      },
      reqOpts
    );

    return lot.status === 200 ? lot._data : undefined;
  }

  async deleteBid(bidUuid: string, bidderUuid: string) {
    const req = await this.delete(`${this.RESOURCE}/delete-bid`, {
      body: {
        bidUuid,
        bidderUuid,
      },
    });

    return req._data;
  }

  async editBid(bidUuid: string, bidderUuid: string, newPrice: number) {
    const req = await this.patch<Bid>(`${this.RESOURCE}/update-bid-price`, {
      bidUuid,
      bidderUuid,
      newPrice,
    });

    return req._data;
  }

  async getBidSales() {
    const uri = `/sales`;
    const response = await this.get<Page<Sale>>(this.RESOURCE + uri);

    return response._data;
  }

  async getBidderFromSale(saleRef: string, internet: boolean = false) {
    const bidder = await this.get<Bidder>(this.RESOURCE, {
      query: { internet, saleRef },
    });
    return bidder._data;
  }

  async getBids(uuid: string) {
    const bids = await this.get<Page<Bid>>(`${this.RESOURCE}/${uuid}/bids`, {
      query: {
        size: 999,
      },
    });
    return bids._data;
  }

  async getBidsFromSale(saleRef: string) {
    const bids = await this.get<Page<Bid>>(
      `${this.RESOURCE}/sales/${saleRef}`,
      {
        query: {
          size: 999,
        },
      }
    );
    return bids._data;
  }

  async registerSale(options: RegisterSaleOptions, reqOpts?: IRequestOptions) {
    const uri = `/register`;
    const origin: Bidder['origin'] = 'INTERNET';

    const lot = await this.post<Bidder>(
      this.RESOURCE + uri,
      {
        absenteeType: 'CLASSIC',
        billingAccount: {
          uuid: options.billingAccountUuid,
        },
        origin: origin,
        saleRef: options.saleRef,
        saleTenant: options.tenant,
        selectedPhone: options.phone,
        type: options.type,
      },
      undefined,
      reqOpts
    );

    return lot.status === 200 ? lot._data : undefined;
  }
}
