/**
 * Extracts the first two sentences from an HTML string.
 * @param htmlString - The HTML string to extract the text from.
 * @returns The first two sentences from the HTML string.
 */
export function extractTextFromHTML(htmlString: string): string {
  // Replace line breaks with spaces
  let cleanText = htmlString.replace(/<br\s*\/?>/gi, ' ');

  // Remove HTML tags to get the clean text
  cleanText = cleanText.replace(/<\/?[^>]+(>|$)/g, '');

  // Check if there are no periods in the cleaned text
  if (!cleanText.includes('.')) {
    return cleanText;
  }
  // This regex matches the end of sentences.
  const sentences = cleanText.match(/[^.!?]+[.!?]/g) || [];

  // Take the first two sentences if available
  const firstSentences = sentences.slice(0, 2).join(' ');

  return firstSentences;
}
