import type { RichTextField } from '@prismicio/client';

import type {
  EditorialContact,
  EditorialLocation,
  EditorialPage,
} from '~/types/cms/editorial';
import type {
  EditoPagePushDocument,
  EditoPagePushDocumentDataCardsLocationCardSlice,
} from '~/types/prismic/types.generated';

import {
  asHTMLField,
  asTabText,
  getAllLanguages,
  getLinkUrl,
  transformImage,
} from './transformCommon';

/**
 * Transforms a Prismic Page Push document into a Editorial object.
 * @param locale - The current locale.
 * @param locales - The available locales.
 * @param uid - The uid of the document.
 * @param prismicEditorial - The Prismic Page Push document to transform.
 * @returns - The transformed Editorial object.
 */
export function transformEditorial(
  locale: string,
  locales: string[],
  uid: string,
  prismicEditorial: EditoPagePushDocument
): EditorialPage {
  const locations = prismicEditorial.data.cards.map(transformLocalisations);
  const seoDescription = locations
    .map((location) => extractTextFromHTML(location.name))
    .join(', ');

  return {
    contacts: prismicEditorial.data.contacts.map(transformContact),
    image: transformImage(prismicEditorial.data.cover_image),
    locations: locations,
    possibleLanguages: getAllLanguages({
      currentLocale: locale,
      currentUid: uid,
      locales: locales,
      prismicAlternateLanguages: prismicEditorial.alternate_languages,
      prismicDefaultLang: prismicEditorial.lang,
    }),
    seoMeta: {
      description: seoDescription,
      ogDescription: seoDescription,
      ogImage: prismicEditorial.data.cover_image.url || undefined,
      ogTitle: asTabText(prismicEditorial.data.tab_title),
    },
    tabTitle: asTabText(prismicEditorial.data.tab_title),
    title: asHTMLField(prismicEditorial.data.main_title),
  };
}

type PrismicContact = {
  job: RichTextField | null | undefined;
  mail: RichTextField | null | undefined;
  name: RichTextField | null | undefined;
  phone: RichTextField | null | undefined;
};

/**
 * Transforms a PrismicContact object into a EditorialContact object.
 * @param prismicContact - The PrismicContact object to transform.
 * @returns - The transformed EditorialContact object.
 */
function transformContact(prismicContact: PrismicContact): EditorialContact {
  return {
    job: asHTMLField(prismicContact.job),
    mail: asHTMLField(prismicContact.mail),
    name: asHTMLField(prismicContact.name),
    phone: asHTMLField(prismicContact.phone),
  };
}

/**
 * Transforms a PrismicLocation object into a EditorialLocation object.
 * @param prismicLocation - The PrismicLocation object to transform.
 * @returns - The transformed EditorialLocation object.
 */
function transformLocalisations(
  prismicLocation: EditoPagePushDocument['data']['cards'][0]
): EditorialLocation {
  // Not rightly typed by Prismic, so we need to cast it.
  const castLocations =
    prismicLocation as EditoPagePushDocumentDataCardsLocationCardSlice;

  return {
    address: asHTMLField(castLocations.primary.address),
    contacts: castLocations.items.map(transformContact),
    image: transformImage(castLocations.primary.image),
    name: asHTMLField(castLocations.primary.country_name),
    url: getLinkUrl(castLocations.primary.country_link),
  };
}
