export type Value<T> = Ref<T> | T;

/**
 * A function that unwrap a Value object. Unwrap it when you
 * need to avoid losing Vue reactivity
 * @param refValue the value to unwrap
 * @returns the unwrap value
 */
export function value<T>(refValue: Value<T>): T {
  return isRef(refValue) ? refValue.value : refValue;
}

/**
 * Get the lang code from the lang
 * @param lang the lang
 * @returns the lang code
 */
export function getLangCode(lang: string) {
  switch (lang) {
    case 'en':
      return 'en-GB';
    case 'fr':
      return 'fr-FR';
    default:
      return 'fr-FR';
  }
}
