import type { PageWithFilter } from '~/types/artcurial/artcurial-domain';

/**
 * Returns an empty page with filter.
 * @returns An empty page with filter.
 * @template T The type of the page content.
 */
export function getEmptyPageWithFilter<T>(): PageWithFilter<T> {
  return {
    filters: [],
    page: {
      content: [],
      currentPage: 0,
      size: 0,
      totalElements: 0,
      totalPages: 0,
      type: {
        annotations: [],
        array: false,
        declaredAnnotations: [],
        primitive: false,
        typeName: 'Item',
        typeParameters: [],
      },
    },
  };
}
