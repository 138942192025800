import type {
  PageWithFilter,
  Sale,
  Step,
} from '~/types/artcurial/artcurial-domain';
import type { SimpleSale } from '~/types/artcurial/sale';

import type { PageRequest } from './requests';

import FetchFactory, { type IRequestOptions } from './factory';

export const transformSales = (resolvedSales: Sale[]) => {
  return resolvedSales.map(transformSale);
};

const transformSale = (resolvedSale: Sale) => {
  resolvedSale.validity.beginDate = new Date(resolvedSale.validity.beginDate);
  resolvedSale.validity.endDate = new Date(resolvedSale.validity.endDate);

  resolvedSale.exhibitions = resolvedSale.exhibitions.map((ex) => {
    ex.validity.beginDate = new Date(ex.validity.beginDate);
    ex.validity.endDate = new Date(ex.validity.endDate);
    return ex;
  });

  return resolvedSale;
};

export default class SalesModule extends FetchFactory {
  private RESOURCE = '/sales';

  async getPersonalSale(saleRef: string, reqOpts?: IRequestOptions) {
    const uri = `${this.RESOURCE}/${saleRef}/personal`;
    const sale = await this.get<SimpleSale>(uri, undefined, reqOpts);

    return sale._data;
  }

  async getSale(saleRef: string, reqOpts?: IRequestOptions) {
    const uri = `${this.RESOURCE}/${saleRef}`;

    const sale = await this.get<Sale>(uri, undefined, reqOpts);
    if (!sale._data) throw new Error();
    return transformSale(sale._data);
  }

  async getSales(
    type: 'calendar' | 'results',
    params?: PageRequest,
    reqOpts?: IRequestOptions
  ): Promise<PageWithFilter<Sale>> {
    const uri = `${this.RESOURCE}/${type}`;

    const filterString = this.buildPageFiltersSale(params);

    try {
      const req = await this.get<PageWithFilter<Sale>>(
        uri + filterString,
        {
          query: this.buildPageRequest(params),
        },
        reqOpts
      );
      const pageWithFilter = req._data;
      if (!pageWithFilter) throw Error();
      pageWithFilter.page.content = transformSales(pageWithFilter.page.content);
      return pageWithFilter;
    } catch (e) {
      console.log('Error while loading sales: ', e);
      return getEmptyPageWithFilter<Sale>();
    }
  }

  async getSteps(saleRef: string, reqOpts?: IRequestOptions) {
    const uri = `${this.RESOURCE}/${saleRef}/steps`;

    const result = await this.get<Step[]>(uri, undefined, reqOpts);
    return result._data;
  }
}
