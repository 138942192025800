import type { ImageField, RichTextField, SliceZone } from '@prismicio/client';

import type {
  SpecialtyContact,
  SpecialtyContactTeam,
  SpecialtyNews,
  SpecialtyPage,
} from '~/types/cms/specialty';
import type {
  EditoPageFocusDocument,
  EditoPageFocusDocumentDataBody1NewsListSlice,
  EditoPageFocusDocumentDataBodyTeamListSlice,
} from '~/types/prismic/types.generated';

import {
  asHTMLField,
  asTabText,
  getAllLanguages,
  getLinkUrl,
  transformImage,
} from './transformCommon';

/**
 * Transforms a Prismic Specialty document into a SpecialtyContent object.
 * @param locale - The current locale.
 * @param locales - The available locales.
 * @param uid - The uid of the document.
 * @param prismicSpecialties - The Prismic Specialty document to transform.
 * @returns - The transformed SpecialtyContent object.
 */
export function transformSpecialty(
  locale: string,
  locales: string[],
  uid: string,
  prismicSpecialties: EditoPageFocusDocument
): SpecialtyPage {
  const seoDescription = extractTextFromHTML(
    asHTMLField(prismicSpecialties.data.main_text)
  );

  return {
    banner: {
      coverYoutubeImage: transformImage(
        prismicSpecialties.data.cover_youtube_image
      ),
      coverYoutubeUrl: getLinkUrl(prismicSpecialties.data.cover_youtube_link),
      imageDesktop: transformImage(prismicSpecialties.data.image_desktop),
      imageMobile: transformImage(prismicSpecialties.data.image_mobile),
    },
    description: {
      additionalText: asHTMLField(prismicSpecialties.data.additional_text),
      // Contacts are in the "body" field of the prismic document.
      contacts: transformContacts(prismicSpecialties.data.body),
      generalContact: {
        email: asHTMLField(prismicSpecialties.data.general_contact_email),
        image: transformImage(prismicSpecialties.data.general_contact_image),
        name: asHTMLField(prismicSpecialties.data.general_contact_description),
        phone: asHTMLField(prismicSpecialties.data.general_contact_phone),
        title: asHTMLField(prismicSpecialties.data.general_contact_title),
      },
      text: asHTMLField(prismicSpecialties.data.main_text),
      title: asHTMLField(prismicSpecialties.data.main_title),
    },
    // News are in the "body1" field of the prismic document.
    news: transformNews(prismicSpecialties.data.body1),
    possibleLanguages: getAllLanguages({
      currentLocale: locale,
      currentUid: uid,
      locales: locales,
      prismicAlternateLanguages: prismicSpecialties.alternate_languages,
      prismicDefaultLang: prismicSpecialties.lang,
    }),
    seoMeta: {
      description: seoDescription,
      ogDescription: seoDescription,
      ogImage: prismicSpecialties.data.image_desktop.url || undefined,
      ogTitle: asTabText(prismicSpecialties.data.page_title),
    },
    tabTitle: asTabText(prismicSpecialties.data.page_title),
  };
}

type PrismicContact = {
  contact_email: RichTextField | null | undefined;
  contact_image: ImageField<never>;
  contact_job: RichTextField | null | undefined;
  contact_name: RichTextField | null | undefined;
  contact_phone_number: RichTextField | null | undefined;
};

/**
 * Transforms a PrismicContact object into a SpecialtyContact object.
 * @param prismicContact - The PrismicContact object to transform.
 * @returns - The transformed SpecialtyContact object.
 */
function transformContact(prismicContact: PrismicContact): SpecialtyContact {
  return {
    email: asHTMLField(prismicContact.contact_email),
    image: transformImage(prismicContact.contact_image),
    name: asHTMLField(prismicContact.contact_name),
    phone: asHTMLField(prismicContact.contact_phone_number),
    title: asHTMLField(prismicContact.contact_job),
  };
}

/**
 * Transforms an array of PrismicContactTeam objects into an array of SpecialtyContactTeam objects.
 * @param prismicContactTeams - The array of PrismicContactTeam objects to transform.
 * @returns The transformed array of SpecialtyContactTeam objects.
 */
function transformContacts(
  prismicContactTeams: SliceZone<EditoPageFocusDocumentDataBodyTeamListSlice>
): SpecialtyContactTeam[] {
  const contactTeams: SpecialtyContactTeam[] = [];
  for (const contactTeam of prismicContactTeams) {
    const members = [];
    for (const member of contactTeam.items) {
      members.push(transformContact(member));
    }
    contactTeams.push({
      members,
      title: asHTMLField(contactTeam.primary.team_title),
    });
  }
  return contactTeams;
}

/**
 * Transforms a PrismicNews object into a SpecialtyNews object.
 * @param prismicNews - The PrismicNews object to transform.
 * @returns - The transformed SpecialtyNews object.
 */
function transformNews(
  prismicNews: SliceZone<EditoPageFocusDocumentDataBody1NewsListSlice>
): SpecialtyNews[] {
  const result: SpecialtyNews[] = [];
  const newsItems = prismicNews[0]?.items;
  if (!newsItems) {
    return result;
  }
  for (const news of newsItems) {
    result.push({
      description: asHTMLField(news.news_text),
      image: transformImage(news.news_image),
      link: {
        label: asHTMLField(news.news_title),
        url: getLinkUrl(news.news_link),
      },
      title: asHTMLField(news.news_title),
      videoUrl: getLinkUrl(news.news_youtube_video_link),
    });
  }
  return result;
}
