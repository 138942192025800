import type { Client } from '@prismicio/client';

import { createClient } from '@prismicio/client';

import type { CMSStrategy } from '~/services/cms/cms.service';
import type { AboutPage } from '~/types/cms/about';
import type { LegalNoticePage } from '~/types/cms/legalNotice';
import type {
  ArticleDocument,
  ContactsPageDocument,
  EditoPageFocusDocument,
  EditoPagePresentationDocument,
  EditoPagePushDocument,
  ExpertiseDayDocument,
  FooterMainDocument,
  HomepageDocument,
  LegalNoticeDocument,
  NewsAreaDocument,
  SellBuyDocument,
} from '~/types/prismic/types.generated';

import { transformAbout } from './transformAbout';
import { transformArticle } from './transformArticle';
import { transformContacts } from './transformContacts';
import { transformEditorial } from './transformEditorial';
import { transformExpertiseDay } from './transformExpertiseDay';
import { transformHome } from './transformHome';
import {
  transformLegalData,
  transformLinks,
  transformSocialLinks,
} from './transformLayout';
import { transformLegalNotice } from './transformLegalNotice';
import { transformNews } from './transformNews';
import { transformSellBuy } from './transformSellBuy';
import { transformSpecialty } from './transformSpecialty';

export class PrismicStrategy implements CMSStrategy {
  client: Client;

  constructor(prismicEndpoint: string) {
    this.client = createClient(prismicEndpoint);
  }

  async getAboutPage(locale: string): Promise<AboutPage> {
    const prismicAbout =
      await this.client.getSingle<EditoPagePresentationDocument>(
        'edito_page_-_presentation',
        { lang: getLangCode(locale) }
      );

    return transformAbout(prismicAbout);
  }

  async getArticlePage(
    articleName: string,
    locale: string,
    allLocales: string[]
  ) {
    const prismicArticle = await this.client.getByUID<ArticleDocument>(
      'article',
      articleName,
      { lang: getLangCode(locale) }
    );

    return transformArticle(locale, allLocales, articleName, prismicArticle);
  }

  async getContactsPage(locale: string) {
    const prismicContacts = await this.client.getSingle<ContactsPageDocument>(
      'contacts_page',
      { lang: getLangCode(locale) }
    );

    return transformContacts(prismicContacts);
  }

  async getEditorialPage(
    editorialName: string,
    locale: string,
    allLocales: string[]
  ) {
    const prismicEditorial = await this.client.getByUID<EditoPagePushDocument>(
      'edito_page_-_push',
      editorialName,
      { lang: getLangCode(locale) }
    );

    return transformEditorial(
      locale,
      allLocales,
      editorialName,
      prismicEditorial
    );
  }

  async getExpertiseDayPage(locale: string) {
    const prismicExpertiseDay =
      await this.client.getSingle<ExpertiseDayDocument>('expertise_day', {
        lang: getLangCode(locale),
      });

    return transformExpertiseDay(prismicExpertiseDay);
  }

  async getFooterContent(locale: string) {
    const prismicFooter = await this.client.getSingle<FooterMainDocument>(
      'footer_main',
      { lang: getLangCode(locale) }
    );

    return {
      legalData: transformLegalData(prismicFooter.data.legal),
      links: transformLinks(prismicFooter.data.links),
      socialLinks: transformSocialLinks(prismicFooter.data.socialLinks),
    };
  }

  getHeaderContent() {
    return { items: [] };
  }

  async getHomePage(locale: string) {
    const prismicHome = await this.client.getSingle<HomepageDocument>(
      'homepage',
      { lang: getLangCode(locale) }
    );
    return transformHome(prismicHome);
  }

  async getLegalNoticePage(
    legalNoticeName: string,
    locale: string,
    allLocales: string[]
  ): Promise<LegalNoticePage> {
    const prismicLegalNotice = await this.client.getByUID<LegalNoticeDocument>(
      'legal_notice',
      legalNoticeName,
      { lang: getLangCode(locale) }
    );

    return transformLegalNotice(
      locale,
      allLocales,
      legalNoticeName,
      prismicLegalNotice
    );
  }

  async getNewsPage(locale: string) {
    const prismicNews = await this.client.getSingle<NewsAreaDocument>(
      'news_area',
      { lang: getLangCode(locale) }
    );

    return transformNews(prismicNews);
  }

  async getSellBuyPage(
    sellBuyName: string,
    locale: string,
    allLocales: string[]
  ) {
    const prismicSellBuy = await this.client.getByUID<SellBuyDocument>(
      'sell_buy',
      sellBuyName,
      { lang: getLangCode(locale) }
    );

    return transformSellBuy(locale, allLocales, sellBuyName, prismicSellBuy);
  }

  async getSpecialtyPage(
    specialtyName: string,
    locale: string,
    allLocales: string[]
  ) {
    const prismicSpecialties =
      await this.client.getByUID<EditoPageFocusDocument>(
        'edito_page_-_focus',
        specialtyName,
        { lang: getLangCode(locale) }
      );

    return transformSpecialty(
      locale,
      allLocales,
      specialtyName,
      prismicSpecialties
    );
  }
}
