import headerDataEn from '@/assets/static/headerDataEn.json';
import headerDataFr from '@/assets/static/headerDataFr.json';

import type { AboutPage } from '~/types/cms/about';
import type { ArticlePage } from '~/types/cms/article';
import type { ContactsPage } from '~/types/cms/contacts';
import type { EditorialPage } from '~/types/cms/editorial';
import type { ExpertiseDayPage } from '~/types/cms/expertiseDay';
import type { HomePage } from '~/types/cms/home';
import type { FooterContent, HeaderContent } from '~/types/cms/layout';
import type { LegalNoticePage } from '~/types/cms/legalNotice';
import type { NewsPage } from '~/types/cms/news';
import type { SellBuyPage } from '~/types/cms/sellBuy';
import type { SpecialtyPage } from '~/types/cms/specialty';

export interface CMSStrategy {
  getAboutPage(locale: string): Promise<AboutPage>;
  getArticlePage(
    articleName: string,
    locale: string,
    allLocales: string[]
  ): Promise<ArticlePage>;
  getContactsPage(locale: string): Promise<ContactsPage>;
  getEditorialPage(
    specialtyName: string,
    locale: string,
    allLocales: string[]
  ): Promise<EditorialPage>;
  getExpertiseDayPage(locale: string): Promise<ExpertiseDayPage>;
  getFooterContent(locale: string): Promise<FooterContent>;
  getHeaderContent(locale: string): HeaderContent;
  getHomePage(locale: string): Promise<HomePage>;
  getLegalNoticePage(
    legalNoticeName: string,
    locale: string,
    allLocales: string[]
  ): Promise<LegalNoticePage>;
  getNewsPage(locale: string): Promise<NewsPage>;
  getSellBuyPage(
    sellBuyName: string,
    locale: string,
    allLocales: string[]
  ): Promise<SellBuyPage>;
  getSpecialtyPage(
    specialtyName: string,
    locale: string,
    allLocales: string[]
  ): Promise<SpecialtyPage>;
}

/**
 * @description A service implementing the strategy pattern that fetches data from a provider.
 * ⚠️ An error will be thrown if no strategies are set ⚠️
 * Using this pattern allows us to easily change CMS providers, or even use several at once if needed.
 * @example <caption> Initializing the service </caption>
 * const cmsService = new CmsService()
 * cmsService.setStrategy(new PrismicStrategy())
 */
export class CmsService implements CMSStrategy {
  strategy?: CMSStrategy;

  getAboutPage(locale: string): Promise<AboutPage> {
    if (!this.strategy) throw new Error('Strategy missing');
    return this.strategy.getAboutPage(locale);
  }

  getArticlePage(articleName: string, locale: string, allLocales: string[]) {
    if (!this.strategy) throw new Error('Strategy missing');
    return this.strategy.getArticlePage(articleName, locale, allLocales);
  }

  getContactsPage(locale: string): Promise<ContactsPage> {
    if (!this.strategy) throw new Error('Strategy missing');
    return this.strategy.getContactsPage(locale);
  }

  getEditorialPage(
    editorialName: string,
    locale: string,
    allLocales: string[]
  ) {
    if (!this.strategy) throw new Error('Strategy missing');
    return this.strategy.getEditorialPage(editorialName, locale, allLocales);
  }

  getExpertiseDayPage(locale: string): Promise<ExpertiseDayPage> {
    if (!this.strategy) throw new Error('Strategy missing');
    return this.strategy.getExpertiseDayPage(locale);
  }

  getFooterContent(locale: string): Promise<FooterContent> {
    if (!this.strategy) throw new Error('Strategy missing');
    return this.strategy.getFooterContent(locale);
  }

  getHeaderContent(locale: string) {
    if (!this.strategy) throw new Error('Strategy missing');
    return (locale === 'fr' ? headerDataFr : headerDataEn) as HeaderContent;
  }

  getHomePage(locale: string) {
    if (!this.strategy) throw new Error('Strategy missing');
    return this.strategy.getHomePage(locale);
  }

  getLegalNoticePage(
    legalNoticeName: string,
    locale: string,
    allLocales: string[]
  ) {
    if (!this.strategy) throw new Error('Strategy missing');
    return this.strategy.getLegalNoticePage(
      legalNoticeName,
      locale,
      allLocales
    );
  }

  getNewsPage(locale: string) {
    if (!this.strategy) throw new Error('Strategy missing');
    return this.strategy.getNewsPage(locale);
  }

  getSellBuyPage(sellBuyName: string, locale: string, allLocales: string[]) {
    if (!this.strategy) throw new Error('Strategy missing');
    return this.strategy.getSellBuyPage(sellBuyName, locale, allLocales);
  }

  getSpecialtyPage(
    specialtyName: string,
    locale: string,
    allLocales: string[]
  ) {
    if (!this.strategy) throw new Error('Strategy missing');
    return this.strategy.getSpecialtyPage(specialtyName, locale, allLocales);
  }

  setStrategy(strategy: CMSStrategy) {
    this.strategy = strategy;
  }
}
