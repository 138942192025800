import type {
  Item,
  PageWithFilter,
  Sale,
} from '~/types/artcurial/artcurial-domain';

import type { PageRequest } from './requests';

import FetchFactory from './factory';
import { transformSales } from './sale.repository';

type ObjectType = 'item' | 'sale';

interface SearchResult {
  items: PageWithFilter<Item>['page'];
  sales: PageWithFilter<Sale>['page'];
}

export default class SearchModule extends FetchFactory {
  private RESOURCE = '/search';

  async getSearch<T extends ObjectType>(
    pageOptions?: { objectType?: T } & Omit<PageRequest, 'filters'>
  ) {
    const uri = `${this.RESOURCE}`;

    const filterString = this.buildPageFilters({ ...pageOptions }, '_fts');

    const searchResult = await this.get<SearchResult>(uri + filterString, {
      query: {
        ...this.buildPageRequest({ ...pageOptions }),
        objectType: pageOptions?.objectType,
      },
    });

    if (!searchResult._data) return undefined;

    if (pageOptions?.objectType === 'item') {
      return {
        items: searchResult._data.items,
      };
    } else if (pageOptions?.objectType === 'sale') {
      return {
        sales: {
          ...searchResult._data.sales,
          content: transformSales(searchResult._data.sales.content),
        },
      };
    }

    return {
      items: searchResult._data.items,
      sales: {
        ...searchResult._data.sales,
        content: transformSales(searchResult._data.sales.content),
      },
    };
  }
}
