import type { HomePage } from '~/types/cms/home';
import type { HomepageDocument } from '~/types/prismic/types.generated';

import { asHTMLField, getLinkUrl, transformImage } from './transformCommon';

/**
 * Transforms a Prismic home document into a Home object.
 * @param prismicHome - The Prismic Home document to transform.
 * @returns - The transformed Home object.
 */
export function transformHome(prismicHome: HomepageDocument): HomePage {
  const newsArea = prismicHome.data.body1;
  const results = prismicHome.data.lots_props;

  return {
    news: newsArea.map((article) => {
      return {
        description: asHTMLField(article.primary.news_abstract),
        image: transformImage(article.primary.news_image),
        link: {
          label: getLinkUrl(article.primary.news_link),
          url: getLinkUrl(article.primary.news_link),
        },
        publicationDate: undefined,
        title: asHTMLField(article.primary.news_title),
      };
    }),
    results: results.map((r) => {
      return {
        fullIndex: String(r.item_index),
        saleRef: String(r.sale_ref),
      };
    }),
  };
}
