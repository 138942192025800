import { callWithNuxt } from '#app/nuxt';

interface ProtectionInfo {
  authOnly: boolean;
  authRedirect: string;
  forceAuth: boolean;
  unAuthOnly: boolean;
  unAuthRedirect: string;
}

export default defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp();
  const localePath = useLocalePath();

  const { signIn, status } = useAuth();

  const protectionInfo = to.meta.protection as ProtectionInfo | undefined;
  if (!protectionInfo) {
    return;
  }

  // If authOnly is set and user is not authenticated
  // redirect to login page by default
  // If authRedirect is set, redirect to that page
  // If forceAuth is set, force the user to authenticate
  if (protectionInfo.authOnly && status.value !== 'authenticated') {
    const callSignIn = async (callBackUrl: string) => {
      await callWithNuxt(nuxtApp, signIn, [
        'auth0',
        { callbackUrl: callBackUrl },
      ]);
    };

    if (protectionInfo.authRedirect) {
      const nextUrl = localePath(protectionInfo.authRedirect);
      await callSignIn(nextUrl);
    } else if (protectionInfo.forceAuth) {
      await callSignIn(to.path);
    } else {
      const nextUrl = localePath('/en/login');
      await callSignIn(nextUrl);
    }
    return;
  }

  if (protectionInfo.unAuthOnly && status.value === 'authenticated') {
    if (protectionInfo.unAuthRedirect) {
      const newRoute = localePath(protectionInfo.unAuthRedirect);
      navigateTo(newRoute);
    } else {
      const newRoute = localePath('/');
      navigateTo(newRoute);
    }
    return;
  }
});
