import type {
  AlternateLanguage,
  FilledContentRelationshipField,
  FilledLinkToWebField,
  ImageField,
  LinkField,
  RichTextField,
} from '@prismicio/client';

import { LinkType, asHTML } from '@prismicio/client';

import type { CmsImage, PossibleLanguages } from '~/types/cms/common';

/**
 * Typeguard to verify that Link is a web link.
 * @param link the link you want to be type guarded
 * @returns A type validation
 */
function isLinkWebField(link: LinkField): link is FilledLinkToWebField {
  return (link as FilledLinkToWebField).link_type === LinkType.Web;
}

/**
 * Typeguard to verify that Link is a document link.
 * @param link the link you want to be type guarded
 * @returns A type validation
 */
function isLinkDocumentField(
  link: LinkField
): link is FilledContentRelationshipField {
  return (
    (link as FilledContentRelationshipField).link_type === LinkType.Document
  );
}

/**
 * Getting the url depending the type of the given link.
 * It also serves as a "router" to translate prismic link to our routes.
 *
 * This URL needs to be used with the i18n local redirect.
 * @param link The link that we want the url from
 * @returns The url to our site
 */
export function getLinkUrl(link: LinkField): string {
  if (isLinkWebField(link)) {
    return link.url;
  } else if (isLinkDocumentField(link)) {
    switch (link.type) {
      case 'homepage':
      case '/':
        return '/';
      case 'edito_page_-_push':
        return `/en/editorial/${link.uid}`;
      case 'edito_page_-_presentation':
        return `/en/about-artcurial`;
      case 'sell_buy':
        return `/en/sell-buy/${link.uid}`;
      case 'expertise_day':
        return `/en/valuation-days`;
      case 'legal_notice':
        return `/en/legal-notice/${link.uid}`;
      case 'edito_page_-_focus':
        return `/en/specialties/${link.uid}`;
      case 'news_area':
        return `/en/news`;
      case 'press_area':
        return `/en/press-center`;
      case 'article':
        return `/en/articles/${link.uid}`;
      case 'contacts_page':
        return `/en/contacts`;
      case 'custom_page':
        switch (link.slug) {
          case 'calendrier-ventes-aux-encheres':
          case 'schedule-auctions-sales':
            return '/en/schedule-auctions-sales';
          case 'resultats-ventes-aux-encheres':
          case 'results-auctions-sales':
            return '/en/results-auctions-sales';
        }
    }
    return ``;
  }
  return '';
}

/**
 * Wrapper of the asHTML function that returns an empty string if the field is null or undefined,
 * or if all the items in the field are empty.
 * @param field - The field to convert to HTML.
 * @returns - The HTML string.
 */
export function asHTMLField(field: RichTextField | null | undefined): string {
  if (!field) {
    return '';
  }
  if (field.every((i) => 'text' in i && !i.text)) {
    return '';
  }
  const htmlText = asHTML(field) || '';
  // Remove <a> tags without an href attribute for SEO purposes.
  const regex = /<a>(.*?)<\/a>/g;
  return htmlText.replace(regex, '$1');
}

/**
 * Wrapper of the asHTML function that returns a string without HTML tags.
 * Also rename the &amp; to &.
 * @param field - The field to convert to text.
 * @returns - The text string.
 */
export function asTabText(field: RichTextField | null | undefined): string {
  const htmlText = asHTMLField(field);
  return htmlText.replace(/<[^>]+>/g, '').replace(/&amp;/g, '&');
}

/**
 * Transforms a Prismic image object into a CmsImage object.
 * It also checks if the image object is empty and returns undefined if it is.
 * @param prismicImage - The Prismic image object to transform.
 * @returns - The transformed CmsImage object.
 */
export function transformImage(
  prismicImage: ImageField<never>
): CmsImage | undefined {
  // The image object can be empty, so we need to check if it has any keys.
  if (!Object.keys(prismicImage).length) {
    return undefined;
  }

  if (!prismicImage.url) return undefined;

  return {
    alt: prismicImage.alt ?? undefined,
    height: prismicImage.dimensions.height,
    src: prismicImage.url,
    width: prismicImage.dimensions.width,
  };
}

type GetAlternateLanguageOptions = {
  currentLocale: string;
  currentUid: string;
  locales: string[];
  prismicAlternateLanguages: AlternateLanguage[];
  prismicDefaultLang: string;
};

/**
 * Get a dict parameter for every language available for this route.
 * e.g. { en: 'artcurial-belgique', fr: 'artcurial-belgium'}
 * @param options - The options object.
 * @param options.currentLocale - The current locale.
 * @param options.currentUid - The current uid.
 * @param options.locales - The available locales.
 * @param options.prismicAlternateLanguages - The alternate languages from Prismic.
 * @returns - The alternate languages mapped by locale.
 */
export function getAllLanguages({
  currentLocale,
  currentUid,
  locales,
  prismicAlternateLanguages,
}: GetAlternateLanguageOptions): PossibleLanguages {
  const otherLocales = locales.filter((i) => i !== currentLocale);

  const langages: PossibleLanguages = {
    [currentLocale]: currentUid,
  };

  for (const altLang of prismicAlternateLanguages) {
    const lang = altLang.lang.split('-')[0] || '';
    if (otherLocales.includes(lang)) {
      langages[lang] = altLang.uid || '';
    }
  }

  // The final object should look like this:
  // { en: 'artcurial-belgique', fr: 'artcurial-belgium'}
  return langages;
}
