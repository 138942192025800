import type { SliceZone } from '@prismicio/client';

import type {
  AdminItem,
  CityItem,
  ContactsPage,
  DepartmentItem,
} from '~/types/cms/contacts';
import type {
  ContactsPageDocument,
  ContactsPageDocumentDataBody1InfosCardSlice,
  ContactsPageDocumentDataBody2InfosCardSlice,
  ContactsPageDocumentDataBody3InfosCardSlice,
  ContactsPageDocumentDataBodyInfosCardSlice,
} from '~/types/prismic/types.generated';

import {
  asHTMLField,
  asTabText,
  getLinkUrl,
  transformImage,
} from './transformCommon';

/**
 * Transforms a Prismic Contacts document into a ContactsPage object.
 * @param prismicContacts - The Prismic Contacts document to transform.
 * @returns - The transformed Contacts object.
 */
export function transformContacts(
  prismicContacts: ContactsPageDocument
): ContactsPage {
  const contactSections = prismicContacts.data.infos.map((section) => ({
    content: asHTMLField(section.description),
    title: asTabText(section.title),
  }));
  const mainSection = transformMainContactBody(prismicContacts.data.body);
  const seoDescription = extractTextFromHTML(
    contactSections.map((section) => section.content).join(', ')
  );

  return {
    adminContent: {
      items: transformAdminItems(prismicContacts.data.body2),
      title: asHTMLField(prismicContacts.data.admin_title),
    },
    cityContent: {
      items: transformCityItems(prismicContacts.data.body3),
      title: asHTMLField(prismicContacts.data.cities_title),
    },
    contactContent: {
      mainSection: mainSection,
      sections: contactSections,
      title: asHTMLField(prismicContacts.data.contacts_title),
    },
    departmentContent: {
      items: transformDepartmentItems(prismicContacts.data.body1),
      title: asHTMLField(prismicContacts.data.deps_title),
    },
    seoMeta: {
      description: seoDescription,
      ogDescription: seoDescription,
      ogImage: mainSection.image?.src || undefined,
      ogTitle: asTabText(prismicContacts.data.contacts_title),
    },
  };
}

/**
 * Transforms the main contact section of the document.
 * @param body - The body slice containing the main contact section.
 * @returns - The transformed main contact section.
 */
function transformMainContactBody(
  body: SliceZone<ContactsPageDocumentDataBodyInfosCardSlice>
) {
  const mainSection = body[0];
  if (!mainSection) return {};

  return {
    image: transformImage(mainSection.primary.image),
    mail: asHTMLField(mainSection.items[0]?.mail),
    phone: asHTMLField(mainSection.items[0]?.phone),
    title: asHTMLField(mainSection.primary.title),
  };
}
/**
 * Transforms the department items of the document.
 * @param body - The body slice containing the department items.
 * @returns - The transformed department items.
 */
function transformDepartmentItems(
  body: SliceZone<ContactsPageDocumentDataBody1InfosCardSlice>
) {
  const items: DepartmentItem[] = [];
  for (const slice of body) {
    const item = slice.items[0];
    items.push({
      image: transformImage(slice.primary.image),
      link: getLinkUrl(slice.primary.link),
      mail: asHTMLField(item?.mail),
      phone: asHTMLField(item?.phone),
      title: asHTMLField(slice.primary.title),
      type: 'department',
    });
  }

  return items;
}

/**
 * Transforms the admin items of the document.
 * @param body - The body slice containing the admin items.
 * @returns - The transformed admin items.
 */
function transformAdminItems(
  body: SliceZone<ContactsPageDocumentDataBody2InfosCardSlice>
) {
  const items: AdminItem[] = [];
  for (const slice of body) {
    const item = slice.items[0];
    items.push({
      job: asHTMLField(item?.job),
      link: getLinkUrl(slice.primary.link),
      mail: asHTMLField(item?.mail),
      name: asHTMLField(item?.name),
      phone: asHTMLField(item?.phone),
      title: asHTMLField(slice.primary.title),
      type: 'admin',
    });
  }

  return items;
}

/**
 * Transforms the city items of the document.
 * @param body - The body slice containing the city items.
 * @returns - The transformed city items.
 */
function transformCityItems(
  body: SliceZone<ContactsPageDocumentDataBody3InfosCardSlice>
) {
  const items: CityItem[] = [];
  for (const slice of body) {
    const item = slice.items[0];
    items.push({
      address: asHTMLField(item?.address),
      contact: asHTMLField(item?.contact),
      image: transformImage(slice.primary.image),
      job: asHTMLField(item?.job),
      link: getLinkUrl(slice.primary.link),
      mail: asHTMLField(item?.mail),
      name: asHTMLField(item?.name),
      phone: asHTMLField(item?.phone),
      title: asHTMLField(slice.primary.title),
      type: 'city',
    });
  }

  return items;
}
