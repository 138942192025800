import { createAuth0 } from '@auth0/auth0-vue';

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();

  const auth0 = createAuth0({
    clientId: String(runtimeConfig.public.auth0.clientId || ''),
    domain: String(runtimeConfig.public.auth0.domain || ''),
    useCookiesForTransactions: false,
  });

  nuxtApp.vueApp.use(auth0);
});
