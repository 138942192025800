import type {
  Address,
  BillingAccount,
  Company,
  Customer,
  FavoriteLot,
  Language,
  LanguageSupport,
  LegalRepresentative,
  Page,
  Person,
  Sale,
} from '~/types/artcurial/artcurial-domain';

import type { PageRequest } from './requests';

import FetchFactory, { type IRequestOptions } from './factory';

interface RegisterCustomerIndividual {
  firstName: string;
  lastName: string;
  locale: string;
  personType: 'individual';
}

interface RegisterCustomerProfessional {
  companyName: string;
  companyType: string;
  firstName: string;
  intracomNumber: string;
  lastName: string;
  locale: string;
  personType: 'professional';
  siren: string;
}

type RegisterCustomer =
  | RegisterCustomerIndividual
  | RegisterCustomerProfessional;

interface UpdateCustomer {
  baseCustomer: Customer;
  birthDate?: Date;
  contacts: { [index: string]: unknown };
  firstName?: string;
  lastName?: string;
  spokenLanguages?: LanguageSupport[];
  title?: string;
  writtenLanguages?: LanguageSupport[];
}

interface CreateBillingAccount {
  alphaTwo: string;
  city: string;
  country: string;
  customerId: string;
  label: string;
  postalCode: string;
  region: string;
  street: string;
  tenant: string;
  type: 'COMPANY' | 'PERSONAL';
}

export default class UserModule extends FetchFactory {
  private RESOURCE = '/customers';

  async addFavorite(
    saleRef: string,
    favoriteLot: FavoriteLot['uuid'],
    reqOpts?: IRequestOptions
  ) {
    const uri = `/favorite-lots`;
    const lot = await this.post<FavoriteLot>(
      this.RESOURCE + uri,
      {
        item: {
          uuid: favoriteLot,
        },
        saleRef,
      },
      undefined,
      reqOpts
    );

    return lot.status === 200 ? lot._data : undefined;
  }

  async createBillingAccount(createBillingAccount: CreateBillingAccount) {
    const uri = `/billing-accounts`;
    const response = await this.post<BillingAccount>(
      this.RESOURCE + uri,
      {
        bidLimitLiveBid: 5000, // limite de bid pour le compte pour les live auction
        bidLimitTimedAuction: 7500, // limite de bid pour le compte pour les timed auction
        contacts: {
          [createBillingAccount.customerId]: {
            '@type':
              'com.artcurial.context.common.domain.model.contact.PostalAddress',
            address: {
              '@type': 'com.artcurial.context.common.domain.model.gis.Address',
              additionalInfo: '',
              city: createBillingAccount.city,
              country: {
                alphaTwo: createBillingAccount.alphaTwo,
              },
              postalCode: createBillingAccount.postalCode,
              state: createBillingAccount.region,
              streetAddress: createBillingAccount.street,
              verified: false,
            } as Address,
            addressType: 'BILLING', // ou SHIPPING ou RESIDENTIAL
            // uuid du contact
            main: true,
            name: createBillingAccount.customerId, // remettre l'uuid en valeur
            privacyType: 'PERSONAL', // (ou PROFESSIONAL) type de contact/adresse
            uuid: createBillingAccount.customerId, // remettre l'uuid en valeur
          },
        },
        label: createBillingAccount.label, // label description du billing account
        type: createBillingAccount.type, // type de BA
        validIntracom: false,
        vatSystem: 'TAX_MARGIN', // TVA appliquée
      },
      { query: { tenant: createBillingAccount.tenant } }
    );
    return response._data;
  }

  async createCustomer(customer: Partial<Customer>, reqOpts?: IRequestOptions) {
    const uri = this.RESOURCE;
    const response = await this.post<Customer>(
      uri,
      customer,
      undefined,
      reqOpts
    );

    if (!response._data) throw new Error();
    return response._data;
  }

  async deleteFavorite(uuid: string) {
    const uri = `/favorite-lots`;
    try {
      const response = await this.delete(this.RESOURCE + `${uri}/${uuid}`);
      return response.status === 200 ? true : false;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
  async getBillingAccounts(tenant: string) {
    const uri = `/billing-accounts`;
    const response = await this.get<Page<BillingAccount>>(this.RESOURCE + uri, {
      query: { tenant },
    });
    return response._data?.content;
  }

  async getCustomer(reqOpts?: IRequestOptions) {
    const customer = await this.get<Customer>(
      this.RESOURCE,
      undefined,
      reqOpts
    );

    if (!customer._data) throw new Error();
    return customer._data;
  }

  async getFavoriteLotsFromSale(saleRef: string, pageRequest: PageRequest) {
    const uri = `/favorite-lots/sales/${saleRef}`;
    const response = await this.get<Page<FavoriteLot>>(this.RESOURCE + uri, {
      query: this.buildPageRequest(pageRequest),
    });

    return response._data;
  }

  async getFavoriteSales() {
    const uri = `/favorite-lots/sales`;
    const response = await this.get<Page<Sale>>(this.RESOURCE + uri);

    return response._data;
  }

  async registerCustomer(options: RegisterCustomer, reqOpts?: IRequestOptions) {
    const uri = this.RESOURCE;

    const language: Language = options.locale === 'fr' ? 'FRENCH' : 'ENGLISH';
    const languagePayload = {
      favorite: true,
      language,
    };

    let partyPayload: Partial<Company> | Partial<Person>;
    if (options.personType === 'individual') {
      const personPayload: Partial<Person> = {
        '@type': 'com.artcurial.context.common.domain.model.party.Person',
        firstName: options.firstName,
        lastName: options.lastName,
        spokenLanguages: [languagePayload],
        writtenLanguages: [languagePayload],
      };
      partyPayload = personPayload;
    } else {
      const companyPayload: Partial<Company> = {
        '@type':
          'com.artcurial.context.common.domain.model.party.organisation.Company',
        identificationNumber: options.siren,
        intracomVAT: options.intracomNumber,
        legalForm: options.companyType,
        legalRepresentative: {
          firstName: options.firstName,
          lastName: options.lastName,
        } as LegalRepresentative,
        name: options.companyName,
        spokenLanguages: [languagePayload],
        writtenLanguages: [languagePayload],
      };
      partyPayload = companyPayload;
    }
    const response = await this.post<Customer>(
      uri,
      {
        '@type': 'com.artcurial.context.ace.domain.model.customer.Customer',
        name: 'Customer',
        party: partyPayload,
      },
      undefined,
      reqOpts
    );

    return response._data;
  }

  async updateCustomer(customer: UpdateCustomer, reqOpts?: IRequestOptions) {
    const uri = this.RESOURCE;
    const response = await this.put<Customer>(
      uri,
      {
        ...customer.baseCustomer,
        party: {
          ...customer.baseCustomer.party,
          birthDate: customer.birthDate?.toISOString(),
          contacts: customer.contacts,
          firstName: customer.firstName,
          lastName: customer.lastName,
          spokenLanguages: customer.spokenLanguages,
          title: customer.title,
          writtenLanguages: customer.writtenLanguages,
        },
      },
      undefined,
      reqOpts
    );

    if (!response._data) throw new Error();
    return response._data;
  }
}
