import { CmsService } from '~/services/cms/cms.service';
import { PrismicStrategy } from '~/services/cms/prismic/prismic.strategy';

export default defineNuxtPlugin(() => {
  const cmsService = new CmsService();

  const { public: config } = useRuntimeConfig();
  if (!config.prismicEndpoint)
    throw new Error('PRISMIC_ENDPOINT environment variable missing.');

  cmsService.setStrategy(new PrismicStrategy(config.prismicEndpoint));

  return {
    provide: {
      cmsService,
    },
  };
});
