import type { $Fetch } from 'ofetch';

import { callWithNuxt } from '#app/nuxt';

import AccountModule from '~/repositories/account.repository';
import BidderModule from '~/repositories/bidder.repository';
import LotsModule from '~/repositories/lots.repository';
import SalesModule from '~/repositories/sale.repository';
import SearchModule from '~/repositories/search.repository';
import UserModule from '~/repositories/user.repository';

export default defineNuxtPlugin(() => {
  const nuxtApp = useNuxtApp();
  const { data, getSession, signIn, signOut } = useAuth();
  const config = useRuntimeConfig();
  const localePath = useLocalePath();
  const apiFetch = $fetch.create({
    baseURL: import.meta.server
      ? String(config.public.ssrBaseUrl || '')
      : String(config.public.apiBaseUrl || ''),

    async onRequest({ options }) {
      // Add auth headers auth here
      const auth0 = data.value?.user.auth0;
      if (auth0) {
        let token = auth0.token;
        if (auth0.expires < Date.now() / 1000) {
          // If force the token to refresh
          const session = await getSession();
          if (session.user.auth0.expires === auth0.expires) {
            // If the refresh failed we sign out the user
            await callWithNuxt(nuxtApp, signOut, [
              { callbackUrl: localePath('/en/login') },
            ]);
          } else {
            // Else use the new token
            token = session.user.auth0.token;
          }
        }
        options.headers.set('Authorization', `Bearer ${token}`);
      }
    },
    async onResponseError({ response }) {
      if (response.status === 401) {
        const expire = data.value?.user.auth0?.expires;
        if (expire !== undefined && expire < Date.now() / 1000) {
          await callWithNuxt(nuxtApp, signIn, ['auth0']);
        }
      }
    },
    retry: 2,
  }) as $Fetch;

  const modules = {
    account: new AccountModule(apiFetch, config.public.mocking),
    bidder: new BidderModule(apiFetch, config.public.mocking),
    lots: new LotsModule(apiFetch, config.public.mocking),
    sales: new SalesModule(apiFetch, config.public.mocking),
    search: new SearchModule(apiFetch, config.public.mocking),
    user: new UserModule(apiFetch, config.public.mocking),
  };

  return {
    provide: {
      api: modules,
    },
  };
});
