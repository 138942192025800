import type { SliceZone } from '@prismicio/client';

import type {
  ArticleBody,
  ArticleBodyImage,
  ArticleBodyParagraph,
  ArticlePage,
} from '~/types/cms/article';
import type {
  ArticleDocument,
  ArticleDocumentDataBodyArticleSectionImageSlice,
  ArticleDocumentDataBodyArticleSectionSemiParagraphsSlice,
  ArticleDocumentDataBodyArticleSectionSlice,
} from '~/types/prismic/types.generated';

import {
  asHTMLField,
  asTabText,
  getAllLanguages,
  transformImage,
} from './transformCommon';

type ArticleDocumentDataBodySlice =
  | ArticleDocumentDataBodyArticleSectionImageSlice
  | ArticleDocumentDataBodyArticleSectionSemiParagraphsSlice
  | ArticleDocumentDataBodyArticleSectionSlice;

/**
 * Transforms a Prismic Article document into a ArticleContent object.
 * @param locale - The current locale.
 * @param locales - The available locales.
 * @param uid - The uid of the document.
 * @param prismicSpecialties - The Prismic Specialty document to transform.
 * @returns - The transformed ArticleContent object.
 */
export function transformArticle(
  locale: string,
  locales: string[],
  uid: string,
  prismicSpecialties: ArticleDocument
): ArticlePage {
  const body = transformBody(prismicSpecialties.data.body);
  const intro = asHTMLField(prismicSpecialties.data.intro);
  const seoImage = getSeoImage(body);
  const seoDescription = getSeoDescription(intro, body);

  return {
    alternateLanguages: getAllLanguages({
      currentLocale: locale,
      currentUid: uid,
      locales: locales,
      prismicAlternateLanguages: prismicSpecialties.alternate_languages,
      prismicDefaultLang: prismicSpecialties.lang,
    }),
    body: transformBody(prismicSpecialties.data.body),
    intro: asHTMLField(prismicSpecialties.data.intro),
    seoMeta: {
      description: seoDescription,
      ogDescription: seoDescription,
      ogImage: seoImage,
      ogTitle: asTabText(prismicSpecialties.data.title),
    },
    tabTitle: asTabText(prismicSpecialties.data.title),
    title: asHTMLField(prismicSpecialties.data.title),
  };
}

/**
 * Checks if a body slice is an image slice.
 * @param bodySlice - The body slice to check.
 * @returns - Whether the body slice is an image slice.
 */
function isSliceImage(
  bodySlice: ArticleDocumentDataBodySlice
): bodySlice is ArticleDocumentDataBodyArticleSectionImageSlice {
  return (
    (bodySlice as ArticleDocumentDataBodyArticleSectionImageSlice)
      .slice_type === 'article_section_image'
  );
}

/**
 * Checks if a body slice is a text slice.
 * @param bodySlice - The body slice to check.
 * @returns - Whether the body slice is a text slice.
 */
function isSliceText(
  bodySlice: ArticleDocumentDataBodySlice
): bodySlice is ArticleDocumentDataBodyArticleSectionSlice {
  return (
    (bodySlice as ArticleDocumentDataBodyArticleSectionSlice).slice_type ===
    'article_section'
  );
}

/**
 * Checks if a body slice is a paragraph slice.
 * @param bodySlice - The body slice to check.
 * @returns - Whether the body slice is a paragraph slice.
 */
function isSliceParagraph(
  bodySlice: ArticleDocumentDataBodySlice
): bodySlice is ArticleDocumentDataBodyArticleSectionSemiParagraphsSlice {
  return (
    (bodySlice as ArticleDocumentDataBodyArticleSectionSemiParagraphsSlice)
      .slice_type === 'article_section_semi_paragraphs'
  );
}

/**
 * Transforms a body slice into an ArticleBody object.
 * @param body - The body slice to transform.
 * @returns - The transformed ArticleBody object.
 */
function transformBody(body: SliceZone<ArticleDocumentDataBodySlice>) {
  const result: ArticleBody[] = body.flatMap((slice) => {
    if (isSliceImage(slice)) {
      const img = transformImage(slice.primary.full_image);
      if (!img) return [];
      return {
        image: img,
        imageCaption: asHTMLField(slice.primary.image_descr),
        type: 'image',
      };
    } else if (isSliceText(slice)) {
      return {
        text: asHTMLField(slice.primary.text),
        title: asHTMLField(slice.primary.section_title),
        type: 'text',
      };
    } else if (isSliceParagraph(slice)) {
      return {
        left: {
          image: transformImage(slice.primary.left_image),
          imageCaption: asHTMLField(slice.primary.left_image_descr),
          text: asHTMLField(slice.primary.left_paragraph),
        },
        right: {
          image: transformImage(slice.primary.right_image),
          imageCaption: asHTMLField(slice.primary.right_image_descr),
          text: asHTMLField(slice.primary.right_paragraph),
        },
        type: 'paragraph',
      };
    } else {
      return [];
    }
  });

  return result;
}

/**
 * Gets an image from the body to use as the SEO image.
 * @param body - The body to search for an image.
 * @returns - The image to use as the SEO image.
 */
function getSeoImage(body: ArticleBody[]): string | undefined {
  const firstImage = body.find((b) => b.type === 'image') as
    | ArticleBodyImage
    | undefined;
  if (firstImage) return firstImage.image.src;

  const firstParagraph = body.find(
    (b) => b.type === 'paragraph' && (b.left.image || b.right.image)
  ) as ArticleBodyParagraph | undefined;

  return firstParagraph?.left.image?.src || firstParagraph?.right.image?.src;
}

/**
 * Gets a description from the body to use as the SEO description
 * @param intro - The intro to search for a description.
 * @param body - The body to search for  a description.
 * @returns - The description to use as the SEO image.
 */
function getSeoDescription(
  intro: string,
  body: ArticleBody[]
): string | undefined {
  if (intro) return extractTextFromHTML(intro);

  const firstText = body.find((b) => b.type !== 'image');
  if (firstText?.type === 'text') return extractTextFromHTML(firstText.text);

  if (firstText?.type === 'paragraph') {
    const finalText = firstText.left.text || firstText.right.text;

    return finalText ? extractTextFromHTML(finalText) : undefined;
  }
  return undefined;
}
