import type {
  AboutContact,
  AboutContactSection,
  AboutPage,
} from '~/types/cms/about';
import type {
  EditoPagePresentationDocument,
  EditoPagePresentationDocumentDataBodyTeamSlice,
} from '~/types/prismic/types.generated';

import { asHTMLField, asTabText, transformImage } from './transformCommon';

/**
 * Transforms a Prismic About document into a About object.
 * @param prismicAbout - The Prismic About document to transform.
 * @returns - The transformed About object.
 */
export function transformAbout(
  prismicAbout: EditoPagePresentationDocument
): AboutPage {
  const seoDescription = extractTextFromHTML(
    asHTMLField(prismicAbout.data.main_text)
  );
  return {
    contactSections: convertContactSections(prismicAbout.data.body),
    dates: prismicAbout.data.dates.map((date) => ({
      text: asHTMLField(date.text),
      year: date.year || 0,
    })),
    datesTitle: asHTMLField(prismicAbout.data.timeline_title),
    mainContent: {
      description: asHTMLField(prismicAbout.data.main_text),
      image: transformImage(prismicAbout.data.main_image),
      imageDescription: asHTMLField(prismicAbout.data.picture_title),
      title: asHTMLField(prismicAbout.data.main_title),
    },
    seoMeta: {
      description: seoDescription,
      ogDescription: seoDescription,
      ogImage: prismicAbout.data.main_image.url || undefined,
      ogTitle: asTabText(prismicAbout.data.tab_title),
    },
    tabTitle: asTabText(prismicAbout.data.tab_title),
  };
}

/**
 * Transforms a PrismicContact object into a EditorialContact object.
 * @param prismicContact - The PrismicContact object to transform.
 * @returns - The transformed EditorialContact object.
 */
function transformContact(
  prismicContact: EditoPagePresentationDocumentDataBodyTeamSlice['items'][0]
): AboutContact {
  return {
    image: transformImage(prismicContact.image),
    job: asHTMLField(prismicContact.job),
    longDescription: asHTMLField(prismicContact.long_description),
    name: asHTMLField(prismicContact.name),
    shortDescription: asHTMLField(prismicContact.short_description),
  };
}

/**
 * Transforms a Prismic contact section object into a AboutContactSection object.
 * @param prismicContactSections - The Prismic contact section object to transform.
 * @returns - The transformed AboutContactSection object.
 */
function convertContactSections(
  prismicContactSections: EditoPagePresentationDocument['data']['body']
): AboutContactSection[] {
  const contactSections: AboutContactSection[] = [];

  for (const prismicContactSection of prismicContactSections) {
    const contacts: AboutContact[] = [];

    for (const prismicContact of prismicContactSection.items) {
      contacts.push(transformContact(prismicContact));
    }

    contactSections.push({
      contacts,
      title: asHTMLField(prismicContactSection.primary.team_title),
    });
  }

  return contactSections;
}
